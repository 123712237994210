import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "../../utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import Label from "../crm/Label";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  { id: "apartment", label: "Apartment", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
];

export default function CustomerSearch({ onUserClick, rpp = 10 }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rpp);
  const [selectedField, setSelectedField] = useState("name");

  const [query, setQuery] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setCustomerData([]);
      setPage(0);
      setQuery(event.target.value);
    }, 1000);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFieldChange = (event) => {
    setSelectedField(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (query && query.length > 0) {
      setLoadingData(true);
      handleSearch();
    }
  }, [query, page, rowsPerPage, activeFranchise, selectedField]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = async () => {
    const ret = await dataFetch(
      `/api/users/mgract/search?q=${query}&f=${selectedField}&skip=${
        page * rowsPerPage
      }&limit=${rowsPerPage}`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  return (
    <Card sx={{ boxShadow: 1 }}>
      <Container>
        <Stack direction="row" spacing={2} sx={{ p: 2 }}>
          <SearchStyle
            placeholder="Search customer..."
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            sx={{ mt: 2, mb: 1 }}
            autoFocus={true}
          />

          <TextField
            select
            id="field-select"
            value={selectedField}
            onChange={handleFieldChange}
            label="Search in"
            sx={{ minWidth: 100 }}
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"address.detail"}>Address</MenuItem>
            <MenuItem value={"code"}>Code</MenuItem>
            <MenuItem value={"phone"}>Phone</MenuItem>
          </TextField>
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((user) => {
                    return (
                      <TableRow hover onClick={() => onUserClick(user)}>
                        <TableCell>{user?.name || "-"}</TableCell>
                        <TableCell>
                          {"xx" + user?.phone?.slice(9) || "-"}
                        </TableCell>
                        <TableCell>
                          {(user?.address?.apartment?.name && (
                            <Label color="primary">
                              {user?.address?.apartment?.name}
                            </Label>
                          )) ||
                            "NA"}
                        </TableCell>

                        <Tooltip title={user.address.detail}>
                          <TableCell>
                            {user.address.detail?.substring(0, 30) || "NA"}
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}

        {query.length === 0 && (
          <Typography variant="body2" align="center" sx={{ mb: 4 }}>
            Please enter a search text!
          </Typography>
        )}

        {customerData.length === 0 &&
          query.length > 0 &&
          loadingData === false && (
            <div>
              <Typography gutterBottom align="center" variant="subtitle1">
                Not found
              </Typography>
              <Typography variant="body2" align="center" sx={{ mb: 4 }}>
                No results found for &nbsp; <strong>&quot;{query}&quot;</strong>
                . Try checking for typos or using complete words.
              </Typography>
            </div>
          )}
      </Container>
    </Card>
  );
}
