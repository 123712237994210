export const onboardingStatus = {
  hotlist: "Hotlist",
  delivery: "Delivery Lead",
  pending: "Pending",
  ongoing: "Ongoing",
  later: "Later",
  duplicate: "Duplicate",
  completed: "Completed",
  "not-interested": "Not Interested",
};

export const states = {
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CT: "Chhattisgarh",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OR: "Odisha",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TG: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UK: "Uttarakhand",
  WB: "West Bengal",
  AN: "Andaman and Nicobar Islands",
  CH: "Chandigarh",
  DN: "Dadra and Nagar Haveli and Daman and Diu",
  DL: "Delhi",
  JK: "Jammu and Kashmir",
  LA: "Ladakh",
  LD: "Lakshadweep",
  PY: "Puducherry",
};

export const employeeRoles = {
  sales: "Delivery",
  tl: "Team Lead",
  fieldexec: "Field Sales",
  manager: "Manager",
  collection: "Collection",
  mechanic: "Mechanic",
  helper: "Helper",
  cook: "Cook",
  lab: "Lab Assistant",
  transport: "Transport",
  hr: "HR",
  crm: "CRM",
  account: "Accounts",
};