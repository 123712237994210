import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "src/contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";
import Label from "src/pages/crm/Label";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  { id: "active", label: "Active", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "center", label: "Center", alignRight: false },
];

export default function LedgerListCard({ onAccSelected, initFr }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [entity, setEntity] = useState("all");
  const [active, setActive] = useState("all");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [selectedFr, setSelectedFr] = useState(initFr ?? "all");
  const [field, setField] = useState("name");
  const [sort, setSort] = useState("open_balance");
  const [dir, setDir] = useState("a");

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const navigate = useNavigate();

  let timer = "";

  const handleChangeQuery = (event, skip) => {
    clearTimeout(timer);
    if (skip) {
      setPage(0);
      setSearch(event.target.value);
    } else {
      timer = setTimeout(function () {
        setPage(0);
        setSearch(event.target.value);
      }, 2000);
    }
  };

  const handleEntityChange = (event) => {
    setEntity(event.target.value);
    setPage(0);
  };

  const handleActiveChange = (event) => {
    setActive(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/accs/query?skip=${
      page * 10
    }&limit=${10}&sort=${sort}&dir=${dir}`;

    if (entity && entity !== "all") url += `&entity=${entity}`;
    if (active && active !== "all") url += `&active=${active}`;
    if (selectedFr && selectedFr !== "all") url += `&franchisee=${selectedFr}`;

    if (search && search.length > 0) url += `&search=${search}&field=${field}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [page, entity, active, search, selectedFr, field, sort, dir]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Search Accounts">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <Select
            id="entity-select"
            value={entity}
            onChange={handleEntityChange}
          >
            <MenuItem value={"all"}>Entity</MenuItem>
            <MenuItem value={"bankaccount"}>Bank Account</MenuItem>
            <MenuItem value={"expense"}>Expense</MenuItem>
            <MenuItem value={"employee"}>Employee</MenuItem>
            <MenuItem value={"center"}>Center</MenuItem>
            <MenuItem value={"manager"}>Manager</MenuItem>
            <MenuItem value={"bulksale"}>Bulk Sale</MenuItem>
            <MenuItem value={"income"}>Income</MenuItem>
            <MenuItem value={"ledger"}>Ledger</MenuItem>
            <MenuItem value={"asset"}>Asset</MenuItem>
          </Select>

          <Select
            id="active-select"
            value={active}
            onChange={handleActiveChange}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"true"}>Active</MenuItem>
            <MenuItem value={"false"}>Inactive</MenuItem>
          </Select>

          <Select
            id="fr-select"
            value={selectedFr}
            onChange={(event) => setSelectedFr(event.target.value)}
          >
            <MenuItem value={"all"}>Center</MenuItem>
            {allFranchisees.map((item) => (
              <MenuItem value={item._id}>{item.name}</MenuItem>
            ))}
          </Select>

          <SearchStyle
            placeholder="Search Accs..."
            onChange={(e) => handleChangeQuery(e)}
            onKeyUp={(e) => e.key == "Enter" && handleChangeQuery(e, true)}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            sx={{ mt: 4, mb: 4 }}
            autoFocus={true}
          />

          <Select
            id="field-select"
            value={field}
            label="Search In"
            onChange={(e) => setField(e.target.value)}
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"phone"}>Phone</MenuItem>
          </Select>
          <Select
            id="sort-select"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <MenuItem value={"_id"}>ID</MenuItem>
            <MenuItem value={"open_balance"}>Bal</MenuItem>
            <MenuItem value={"name"}>Name</MenuItem>
          </Select>
          <Select
            id="dir-select"
            value={dir}
            onChange={(e) => setDir(e.target.value)}
          >
            <MenuItem value={"a"}>ASC</MenuItem>
            <MenuItem value={"d"}>DESC</MenuItem>
          </Select>
        </Stack>
        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {customerData.length > 0 && (
                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow
                        hover
                        onClick={() =>
                          typeof onAccSelected === "function"
                            ? onAccSelected(item)
                            : navigate("/admin/accounts/details", {
                                state: {
                                  account: item,
                                },
                              })
                        }
                        style={{
                          backgroundColor:
                            item.active === false ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>
                          <b>{item ? item.name : ""}</b>
                        </TableCell>
                        <TableCell>
                          {item && item.phone ? item.phone : "-"}
                        </TableCell>
                        <TableCell>
                          {item && item.active === true ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell>
                          <Label color="primary" variant="outlined">
                            {item?.entity?.toUpperCase() || "-"}
                          </Label>
                        </TableCell>
                        <TableCell>
                          {(item?.franchisee?.name && (
                            <Label color="primary">
                              {item.franchisee.name}
                            </Label>
                          )) ||
                            "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10]}
          />
        </div>
      </Container>
    </Card>
  );
}
