import {
  Card,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Grid,
  Modal,
  Stack,
  TableContainer,
  TableHead,
  Table,
  CircularProgress,
  Drawer,
  CardActionArea,
} from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { getTimeStringNew } from "src/utils/date-time-helper";
import { EditApartmentModal } from "./EditApartmentModal";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import EditManagerModal from "./EditManagerModal";
import MilkCartSearch from "./CartSearch";
import UploadAptModal from "./UploadAptImage";
import UploadAptImage from "./UploadAptImage";
import { getSlotValue, societyApps } from "../addapt/AddAptModal";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone Number", alignRight: true },
  { id: "time", label: "Timing", alignRight: true },
];

const MGR_TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone Number", alignRight: true },
];

export default function ApartmentProfile({ apartment }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/apartments/mgract/appt/${apartment._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
    }
    setLoadingData(false);
  };

  const updateAssignedCart = async (cart) => {
    setLoadingData(true);
    const response = await dataPost(
      `/api/apartments/mgract/appt/${apartment._id}/cart`,
      await getAccessToken(),
      { cart: cart },
      activeFranchise
    );

    if (response.success === true) {
      loadData();
    }

    setLoadingData(false);
  };

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const [uploadImageModal, setUploadImageModal] = useState(false);
  const closeUploadImageModal = () => {
    setUploadImageModal(false);
    loadData();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={2}>
            <Card>
              <Stack direction="column" alignItems="center" spacing={1} pb={2}>
                <div>
                  <img
                    src={
                      (currentData && currentData.image_url) ||
                      "https://milkvilla.in/images/mv_new_logo.jpg"
                    }
                    alt="Apartment"
                    style={{ maxHeight: "400px", objectFit: "cover" }}
                  />
                </div>

                <Typography variant="h6">{currentData?.name || ""}</Typography>

                <Typography variant="body1">
                  {currentData?.society_type || "-"}
                </Typography>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUploadImageModal(true)}
                >
                  Update Image
                </Button>
              </Stack>
            </Card>

            <Card>
              <CardActionArea
                sx={{ p: 2 }}
                onClick={() => setSearchDrawerState(true)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography variant="h6">Cart:</Typography>
                  {loadingData === true && <CircularProgress />}

                  <Typography variant="subtitle1">
                    {currentData?.cart?.name || "-"}
                  </Typography>
                </Stack>
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              {apartment && (
                <ApartmentDetailsCard
                  doc={currentData ? currentData : apartment}
                  reload={loadData}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {apartment && (
                <ManagerDetailsCard
                  doc={currentData ? currentData : apartment}
                  reload={loadData}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Drawer
        anchor={"right"}
        open={searchDrawerState ? true : false}
        onClose={() => setSearchDrawerState(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <MilkCartSearch
          onCartSelected={(cart) => {
            setSearchDrawerState(null);
            updateAssignedCart(cart._id);
          }}
        />
      </Drawer>

      <Modal open={uploadImageModal} onClose={closeUploadImageModal}>
        <UploadAptImage
          handleM1Close={closeUploadImageModal}
          aptId={apartment._id}
        />
      </Modal>
    </>
  );
}

const ManagerDetailsCard = ({ doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Manager Details
        </Typography>

        <Button
          variant="outlined"
          color="error"
          startIcon={<EditIcon />}
          sx={{ mr: 1 }}
          onClick={openModalFn}
        >
          Edit
        </Button>
      </Stack>
      <Stack sx={{ mt: 1 }}>
        <TableContainer>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {MGR_TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>{doc?.manager?.name || "-"}</TableCell>
                <TableCell align="right">
                  {doc?.manager?.phone || "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Modal open={modalOpen} onClose={closeModalFn}>
        <EditManagerModal handleM1Close={closeModalFn} apartment={doc} />
      </Modal>
    </Card>
  );
};

const ApartmentDetailsCard = ({ doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Details
        </Typography>

        <Button
          variant="outlined"
          color="error"
          startIcon={<EditIcon />}
          onClick={openModalFn}
        >
          Edit
        </Button>
      </Stack>

      {doc && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Active:</b>
                </TableCell>
                <TableCell>{doc.active ? "Yes" : "No"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Flats:</b>
                </TableCell>
                <TableCell>{doc.flats}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Occupied:</b>
                </TableCell>
                <TableCell>{doc.flats_occupied}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>No of Towers:</b>
                </TableCell>
                <TableCell>{doc.tower_count}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Occupied:</b>
                </TableCell>
                <TableCell>{doc.flats_occupied}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Price:</b>
                </TableCell>
                <TableCell>₹ {doc.property_price}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Rent:</b>
                </TableCell>
                <TableCell>₹ {doc.property_rent}</TableCell>
              </TableRow>
            </TableBody>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Cart:</b>
                </TableCell>
                <TableCell>
                  {doc.cart ? doc.cart.name : "Not Assigned"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Society App:</b>
                </TableCell>
                <TableCell>{societyApps[doc.society_app] || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Elevator:</b>
                </TableCell>
                <TableCell>{doc.elevator === true ? "Yes" : "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>OTP:</b>
                </TableCell>
                <TableCell>{doc.otp || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Address:</b>
                </TableCell>
                <TableCell>{doc.location.detail}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Pincode:</b>
                </TableCell>
                <TableCell>{doc.pincode}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Slots:</b>
                </TableCell>
                <TableCell>
                  {doc?.slots?.map((slot) => getSlotValue(slot)).join(", ") ||
                    "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Grid>
        </Grid>
      )}

      <Modal open={modalOpen} onClose={closeModalFn}>
        <EditApartmentModal handleM1Close={closeModalFn} apartment={doc} />
      </Modal>
    </Card>
  );
};

//<EditEmployeeModal handleM1Close={closeModalFn} employee={doc} />
