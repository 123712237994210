import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Drawer,
  IconButton,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataFetch, dataPatch } from "src/utils/data-fetch";
import EmpSearch from "../add/EmpSearch";
import { employeeRoles } from "src/utils/constants";
import { CloseRounded } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditEmployeeOfficialDetailModal({
  handleM1Close,
  employee,
}) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const [loadingData, setLoadingData] = useState(false);

  const [mgrDrawerState, setMgrDrawerState] = useState(false);
  const [selectMgr, setSelectMgr] = useState(
    employee?.employee_det?.reporting_manager
  );

  const [deptData, setDeptData] = useState([]);

  const getAllDept = async () => {
    setLoadingData(true);

    let url = `/api/departments/mgract/query?sort=name&dir=a`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDeptData(ret.data);
    } else {
      setError("Error fetching departments");
    }

    setLoadingData(false);
  };

  const approveNow = async (values) => {
    if (!selectMgr) {
      setError("Please select a reporting manager!");
      return;
    } else {
      setError(null);
    }

    let up = {
      designation: values.designation,
      reporting_manager: selectMgr?.employee,
      department: values.department,
      roles: values.roles,
    };

    const ret = await dataPatch(
      `/api/employees/mgract/v2/employee/${employee?._id}`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    designation: Yup.string().required("Designation is required"),
    department: Yup.string().required("Department is required"),
    roles: Yup.array().required("Roles are required"),
  });

  const formik = useFormik({
    initialValues: {
      designation: employee?.employee_det?.designation || "",
      department: employee?.employee_det?.department?._id || "",
      roles: employee?.employee_det?.roles || [],
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    values,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    getAllDept();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Edit Employee
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                select
                fullWidth
                label="Department"
                {...getFieldProps("department")}
                error={Boolean(touched.department && errors.department)}
                helperText={touched.department && errors.department}
              >
                {deptData.map((dept) => (
                  <MenuItem key={dept._id} value={dept._id}>
                    {dept.name}
                  </MenuItem>
                ))}
              </TextField>

              <OutlinedInput
                fullWidth
                type="text"
                value={selectMgr?.name || "None Selected"}
                onClick={() => setMgrDrawerState(true)}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Reporting Mgr</Box>
                  </InputAdornment>
                }
              />

              <TextField
                fullWidth
                type="text"
                label="Designation"
                {...getFieldProps("designation")}
                value={values.designation.toUpperCase()}
                onChange={(e) =>
                  setFieldValue("designation", e.target.value.toUpperCase())
                }
                error={Boolean(touched.designation && errors.designation)}
                helperText={touched.designation && errors.designation}
              />

              <Stack spacing={2} direction="row" alignItems={"center"}>
                <Select
                  fullWidth
                  id="roles-select"
                  label="Roles"
                  multiple
                  {...getFieldProps("roles")}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={employeeRoles[value]} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(employeeRoles).map((key) => (
                    <MenuItem key={key} value={key}>
                      {employeeRoles[key]}
                    </MenuItem>
                  ))}
                </Select>
                <IconButton onClick={() => setFieldValue("roles", [])}>
                  <CloseRounded />
                </IconButton>
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Drawer
        anchor={"right"}
        open={mgrDrawerState}
        onClose={() => setMgrDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <EmpSearch
          onMgrSelected={(dept) => {
            setMgrDrawerState(false);
            setSelectMgr(dept);
          }}
        />
      </Drawer>
    </div>
  );
}
