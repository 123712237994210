import {
  CalculateRounded,
  CloseRounded,
  Download,
  DownloadRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataPost } from "src/utils/data-fetch";
import AddEmployeeDetailModal from "../det/EmployeeDetailsModal";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export const SalaryCalculator = ({ handleClose, drawerOpen }) => {
  const [field, setField] = useState("ctc");
  const [state, setState] = useState("karnataka");
  const [value, setValue] = useState(""); // Initialize value properly
  const [currentData, setCurrentData] = useState(null); // Start as null
  const [pfState, setPfState] = useState("pfEnabled");
  const [addDetailModalOpen, setAddDetailModalOpen] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangeQuery = (event) => {
    setValue(event.target.value);
  };

  const [loadingData, setLoadingData] = useState(false);

  const loadData = async () => {
    if (!value) return;

    setLoadingData(true);
    let url = `/api/salary/mgract/salarystruct`;
    const up = {
      value: parseInt(value),
      field: field,
      state: state,
      pfEnabled: pfState === "pfEnabled",
    };

    console.log(up);

    const ret = await dataPost(
      url,
      await getAccessToken(),
      up,
      activeFranchise
    );

    if (ret.success === true) {
      setCurrentData(ret.data);
      console.log(ret.data);
    }
    setLoadingData(false);
  };

  const formatCurrency = (value) => {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      return "--";
    }
    return numberValue.toFixed(2);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "70%",
          maxWidth: "70%",
          margin: 0,
          borderRadius: 0,
        },
      }}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Salary Calculator
          </Typography>

          <Stack direction={"row"} spacing={1} edge="end">
            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: "500px", pb: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          spacing={2}
        >
          <SearchStyle
            placeholder={
              field === "ctc"
                ? "Calculate salary structure from Annual CTC"
                : "Calculate Salary structure from monthly In Hand"
            }
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <CalculateRounded />
                </Box>
              </InputAdornment>
            }
          />

          <Select
            id="field-select"
            value={field}
            onChange={(e) => setField(e.target.value)}
          >
            <MenuItem value={"ctc"}>CTC</MenuItem>
            <MenuItem value={"inhand"}>In Hand</MenuItem>
          </Select>

          <Select
            id="field-select"
            value={pfState}
            onChange={(e) => setPfState(e.target.value)}
          >
            <MenuItem value={"pfEnabled"}>PF Enabled</MenuItem>
            <MenuItem value={"pfDisabled"}>PF Disabled</MenuItem>
          </Select>

          <Select
            id="state-select"
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <MenuItem value={"other"}>Other</MenuItem>
            <MenuItem value={"karnataka"}>Karnataka</MenuItem>
            <MenuItem value={"bihar"}>Bihar</MenuItem>
          </Select>

          {loadingData ? (
            <CircularProgress color="secondary" />
          ) : (
            <IconButton onClick={loadData}>
              <Button variant="contained" color="primary" sx={{ py: 2 }}>
                <Typography variant="button">Calculate</Typography>
              </Button>
            </IconButton>
          )}
        </Stack>

        {currentData && (
          <TableContainer component={Paper}>
            <Table sx={{ border: "1px solid black", my: 1, padding: 0 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                    <b>Salary Component</b>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                    <b>Monthly</b>
                  </TableCell>
                  <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                    <b>Annual (INR)</b>
                  </TableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black" , padding: "8px" }}>
                      Basic
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.basic / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black" , padding: "8px" }}>
                      {formatCurrency(currentData?.basic)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      HRA
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black" , padding: "8px" }}>
                      {formatCurrency(currentData?.hra / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.hra)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black" , padding: "8px" }}>
                      LTA
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.lta / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.lta)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      Special Allowance
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.specialAllowance / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.specialAllowance)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {" "}
                      <b>Gross Salary</b>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      <b>{formatCurrency(currentData?.grossSalary / 12)}</b>
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {" "}
                      <b>{formatCurrency(currentData?.grossSalary)} </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      PF Employer
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {" "}
                      {formatCurrency(currentData?.pfEmployer / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.pfEmployer)}
                    </TableCell>
                  </TableRow>

                  <TableRow sx={{ backgroundColor: "lightblue" }}>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      <b>CTC</b>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      <b> {formatCurrency(currentData?.ctc / 12)} </b>
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      <b>{formatCurrency(currentData?.ctc)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      ESIC
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {" "}
                      {formatCurrency(currentData?.esic / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.esic)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      PF Employee
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.pfEmployee / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.pfEmployee)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      PF Employer
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.pfEmployer / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.pfEmployer)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      PTAX
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.ptax / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.ptax)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      TDS
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      {formatCurrency(currentData?.tds / 12)}
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black" , padding: "8px" }}>
                      {formatCurrency(currentData?.tds)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: "lightblue" }}>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      <b>In Hand</b>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px"  }}>
                      <b> {formatCurrency(currentData?.inhand / 12)} </b>
                    </TableCell>
                    <TableCell align="right" sx={{ border: "1px solid black", padding: "8px"  }}>
                      <b>{formatCurrency(currentData?.inhand)}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
            </Table>
          </TableContainer>
        )}

        {currentData && (
          <Stack direction={"row"} spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddDetailModalOpen(true)}
              sx={{ py: 1 }}
            >
              <Typography variant="button">Download Offer letter</Typography>
              <DownloadRounded />
            </Button>
          </Stack>
        )}
      </Container>

      <Modal
        open={addDetailModalOpen}
        onClose={() => setAddDetailModalOpen(false)}
      >
        <AddEmployeeDetailModal
          handleM1Close={() => setAddDetailModalOpen(false)}
          details={currentData}
        />
      </Modal>
    </Dialog>
  );
};


// {field === "inhand" ? (
//   <TableBody>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         Basic
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.basic)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.basic * 12)}
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         HRA
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.hra)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.hra * 12)}
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         LTA
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.lta)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.lta * 12)}
//       </TableCell>
//     </TableRow>

//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         Special Allowance
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.specialAllowance)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.specialAllowance * 12)}
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {" "}
//         <b>Gross Salary</b>
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         <b>{formatCurrency(currentData?.grossSalary)}</b>
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {" "}
//         <b>{formatCurrency(currentData?.grossSalary * 12)} </b>
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         PF Employer
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {" "}
//         {formatCurrency(currentData?.pfEmployer)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.pfEmployer * 12)}
//       </TableCell>
//     </TableRow>

//     <TableRow sx={{ backgroundColor: "lightblue" }}>
//       <TableCell sx={{ border: "1px solid black" }}>
//         <b>CTC</b>
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         <b> {formatCurrency(currentData?.ctc)} </b>
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         <b>{formatCurrency(currentData?.ctc * 12)}</b>
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         ESIC
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {" "}
//         {formatCurrency(currentData?.esic)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.esic * 12)}
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         PF Employee
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.pfEmployee)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.pfEmployee * 12)}
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         PF Employer
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.pfEmployer)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.pfEmployer * 12)}
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         PTAX
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.ptax)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.ptax * 12)}
//       </TableCell>
//     </TableRow>

//     <TableRow>
//       <TableCell sx={{ border: "1px solid black" }}>
//         TDS
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.tds)}
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         {formatCurrency(currentData?.tds * 12)}
//       </TableCell>
//     </TableRow>
//     <TableRow sx={{ backgroundColor: "lightblue" }}>
//       <TableCell sx={{ border: "1px solid black" }}>
//         <b>In Hand</b>
//       </TableCell>
//       <TableCell sx={{ border: "1px solid black" }}>
//         <b> {formatCurrency(currentData?.inhand)} </b>
//       </TableCell>
//       <TableCell align="right" sx={{ border: "1px solid black" }}>
//         <b>{formatCurrency(currentData?.inhand * 12)}</b>
//       </TableCell>
//     </TableRow>
//   </TableBody>
// ) : (