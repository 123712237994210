import {
  Container,
  Typography,
  Stack,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Box,
  IconButton,
  Tab,
  TextField,
  Tooltip,
  Grid,
  Checkbox,
  Dialog,
  Drawer,
  Divider,
} from "@mui/material";
import { useAuth } from "src/contexts/AuthContext";

import Page from "src/components/Page";
import { useEffect, useState } from "react";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import { getDateStringCustom } from "src/utils/date-time-helper";
import { SingleOnboarding } from "./SingleOnboarding";
import Label from "./Label";
import { styled } from "@mui/material/styles";
import {
  AssignmentRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import NewRechargeSection from "./NewRechargeSections";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";
import CustomerSearch from "../home/CustomerSearch";
import ManagerSearch from "../manager/ManagerSearch";
import ApartmentSearch from "../apartment/ApartmentSearch";
import NewCustomersSection from "./NewCustomersSection";
import LowBalanceSection from "./LowBalanceSection";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function CRM() {
  const [onbCustomer, setOnbCustomer] = useState();
  const [onbDrawerOpen, setOnbDrawerOpen] = useState(false);

  const { activeFranchise, getAccessToken, currentManager } = useAuth();

  const [selectDir, setSelectDir] = useState("d");
  const [selectCart, setSelectCart] = useState("all");

  const [freeSampleCredit, setFreeSampleCredit] = useState("all");
  const [freeSampleVerify, setFreeSampleVerify] = useState("all");

  const [userActive, setUserActive] = useState("all");

  const [appDownloaded, setAppDownloaded] = useState("all");
  const [subscriptionStatus, setSubscriptionStatus] = useState("all");

  const [selectMgr, setSelectMgr] = useState(
    localStorage.getItem("crm_select_mgr") || currentManager._id
  );
  const [selectMgrList, setSelectMgrList] = useState({
    all: "ALL",
    [currentManager._id]: "My",
    none: "None",
    other: "Other",
  });

  const [managerSearchDrawerState, setManagerSearchDrawerState] =
    useState(false);

  const [selectApt, setSelectApt] = useState("all");
  const [selectAptList, setSelectAptList] = useState({
    all: "ALL",
    other: "Other",
  });

  const [aptSearchDrawerState, setAptSearchDrawerState] = useState(false);

  const handleAptChange = (event) => {
    if (event.target.value === "other") {
      setAptSearchDrawerState(true);
    } else {
      setSelectApt(event.target.value);
    }
  };

  const aptSearchDrawerReturn = (acc) => {
    setSelectAptList({ ...selectAptList, [acc._id]: acc.name });
    setSelectApt(acc._id);
    setAptSearchDrawerState(false);
  };

  const [dnp, setDnp] = useState("all");
  const [cartData, setCartData] = useState([]);

  const getMilkcarts = async () => {
    let url = `/api/milkcarts/mgract/query?sort=name&dir=a`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCartData(ret.data);
    }
  };

  useEffect(() => {
    getMilkcarts();
  }, [activeFranchise]);

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("crm_rows_per_page") || 20
  );

  const handleDirChange = (event) => {
    setSelectDir(event.target.value);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(parseInt(rows));
    localStorage.setItem("crm_rows_per_page", rows);
  };

  const handleCartChange = (event) => {
    setSelectCart(event.target.value);
  };

  const handleDnpChange = (event) => {
    setDnp(event.target.value);
  };

  const handleUserActiveChange = (event) => {
    setUserActive(event.target.value);
  };

  const handleMgrChange = (event) => {
    if (event.target.value === "other") {
      setManagerSearchDrawerState(true);
    } else {
      setSelectMgr(event.target.value);
      localStorage.setItem("crm_select_mgr", event.target.value);
    }
  };

  const managerSearchDrawerReturn = (acc) => {
    setSelectMgrList({ ...selectMgrList, [acc._id]: acc.name });
    setSelectMgr(acc._id);
    setManagerSearchDrawerState(false);
  };

  const handleFreeSampleCreditChange = (event) => {
    setFreeSampleCredit(event.target.value);
  };

  const handleFreeSampleVerifyChange = (event) => {
    setFreeSampleVerify(event.target.value);
  };

  const handleAppDownloadedChange = (event) => {
    setAppDownloaded(event.target.value);
  };

  const handleSubscriptionStatusChange = (event) => {
    setSubscriptionStatus(event.target.value);
  };

  const handleSingleOnboarding = (customer) => {
    setOnbCustomer(customer);
    setOnbDrawerOpen(true);
  };

  const [currentMenu, setCurrentMenu] = useState(
    localStorage.getItem("crm_default_menu") || "pending"
  );

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
    localStorage.setItem("crm_default_menu", newValue);
  };

  const [countData, setCountData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const loadCounts = async () => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboard/count`;
    if (selectMgr !== "all" && selectMgr !== "none") {
      url += `?manager=${selectMgr}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCountData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    loadCounts();
  }, [activeFranchise, selectMgr]);

  const [custDrawerOpen, setCustDrawerOpen] = useState(false);
  const [selectedCust, setSelectedCust] = useState(null);

  const handleCustomerOpen = (custData) => {
    setSelectedCust(custData);
    setCustDrawerOpen(true);
  };

  return (
    <Page title="CRM Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">CRM Dashboard</Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              select
              id="mgr-select"
              value={selectMgr}
              onChange={(e) => {
                handleMgrChange(e);
              }}
              label="Mgr"
              SelectProps={{
                style: { color: selectMgr === "all" ? "black" : "red" },
              }}
            >
              {Object.keys(selectMgrList).map((mgr) => (
                <MenuItem value={mgr}>{selectMgrList[mgr]}</MenuItem>
              ))}
            </TextField>

            <TextField
              select
              id="cart-select"
              value={selectCart}
              onChange={(e) => {
                handleCartChange(e);
              }}
              label="Cart"
              SelectProps={{
                style: { color: selectCart === "all" ? "black" : "red" },
              }}
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"none"}>No Cart</MenuItem>
              {cartData?.map((cart) => (
                <MenuItem value={cart._id}>{cart.name}</MenuItem>
              ))}
            </TextField>

            <TextField
              select
              id="apt-select"
              value={selectApt}
              onChange={(e) => {
                handleAptChange(e);
              }}
              label="Apt"
              SelectProps={{
                style: { color: selectApt === "all" ? "black" : "red" },
              }}
            >
              {Object.keys(selectAptList).map((apt) => (
                <MenuItem value={apt}>{selectAptList[apt]}</MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <TabContext value={currentMenu}>
              <TabList
                onChange={handleTabChange}
                variant="scrollable"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab
                  label={
                    <Tooltip title="For most probable conversions.">
                      <span>HOTLIST</span>
                    </Tooltip>
                  }
                  value="hotlist"
                  {...a11yProps(0)}
                  sx={{ color: "red" }}
                />
                <Tab
                  label={
                    <Tooltip title="Show in complusary leads section in delivery app. Only add if escalation is required.">
                      <span>DELIVERY</span>
                    </Tooltip>
                  }
                  value="delivery"
                  {...a11yProps(1)}
                  sx={{ color: "red" }}
                />
                <Tab
                  label={
                    <Tooltip title="New user registrations come here automatically. Immediate calls are required.">
                      <span>Pending</span>
                    </Tooltip>
                  }
                  value="pending"
                  {...a11yProps(2)}
                />
                {countData && countData.pending > 0 && (
                  <Label variant="outlined" color="error">
                    {countData.pending}
                  </Label>
                )}
                <Tab
                  label={
                    <Tooltip title="Sample ongoing or completed, need to move to later, not interested, or completed.">
                      <span>Ongoing</span>
                    </Tooltip>
                  }
                  value="ongoing"
                  {...a11yProps(3)}
                />
                {countData && countData.pending > 0 && (
                  <Label variant="outlined" color="primary">
                    {countData.ongoing}
                  </Label>
                )}
                <Tab
                  label={
                    <Tooltip title="Interested but will subscribe after some time. Need to revisit.">
                      <span>Later</span>
                    </Tooltip>
                  }
                  value="later"
                  {...a11yProps(4)}
                />
                {countData && countData.pending > 0 && (
                  <Label variant="outlined" color="warning">
                    {countData.later}
                  </Label>
                )}
                <Tab
                  label={
                    <Tooltip title="Subscription completed. Need to revisit old completed customers who are now inactive.">
                      <span>Completed</span>
                    </Tooltip>
                  }
                  value="completed"
                  {...a11yProps(5)}
                />
                <Tab label="Not Interested" value="ni" {...a11yProps(6)} />
                <Tab label="All" value="allonb" {...a11yProps(7)} />
                <Tab label="New Recharge" value="rech" {...a11yProps(8)} />
                <Tab label="Low Balance" value="lowbal" {...a11yProps(9)} />
                <Tab label="New Customers" value="newcust" {...a11yProps(10)} />
              </TabList>

              <TabPanel value="hotlist">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"hotlist"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  handleAptChange={handleAptChange}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                />
              </TabPanel>

              <TabPanel value="delivery">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"delivery"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  handleAptChange={handleAptChange}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                />
              </TabPanel>

              <TabPanel value="pending">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"pending"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  handleAptChange={handleAptChange}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                />
              </TabPanel>

              <TabPanel value="ongoing">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"ongoing"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  handleAptChange={handleAptChange}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                />
              </TabPanel>

              <TabPanel value="later">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"later"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  handleAptChange={handleAptChange}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                />
              </TabPanel>

              <TabPanel value="completed">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"completed"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  handleAptChange={handleAptChange}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                />
              </TabPanel>

              <TabPanel value="ni">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"not-interested"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                  handleAptChange={handleAptChange}
                />
              </TabPanel>

              <TabPanel value="allonb">
                <OnboardingCard
                  selectEntry={handleSingleOnboarding}
                  onbStatus={"all"}
                  onOpenCustomer={handleCustomerOpen}
                  rowsPerPage={rowsPerPage}
                  selectDir={selectDir}
                  selectCart={selectCart}
                  selectMgr={selectMgr}
                  dnp={dnp}
                  handleCartChange={handleCartChange}
                  handleDirChange={handleDirChange}
                  handleMgrChange={handleMgrChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  freeSampleCredit={freeSampleCredit}
                  freeSampleVerify={freeSampleVerify}
                  appDownloaded={appDownloaded}
                  subscriptionStatus={subscriptionStatus}
                  handleDnpChange={handleDnpChange}
                  handleAppDownloadedChange={handleAppDownloadedChange}
                  handleFreeSampleCreditChange={handleFreeSampleCreditChange}
                  handleFreeSampleVerifyChange={handleFreeSampleVerifyChange}
                  handleSubscriptionStatusChange={
                    handleSubscriptionStatusChange
                  }
                  selectApt={selectApt}
                  handleAptChange={handleAptChange}
                  userActive={userActive}
                  handleUserActiveChange={handleUserActiveChange}
                />
              </TabPanel>
              <TabPanel value="rech">
                <NewRechargeSection />
              </TabPanel>

              <TabPanel value="lowbal">
                <LowBalanceSection
                  selectCart={selectCart}
                  handleCartChange={handleCartChange}
                />
              </TabPanel>

              <TabPanel value="newcust">
                <NewCustomersSection
                  selectMgr={selectMgr}
                  selectCart={selectCart}
                />
              </TabPanel>
            </TabContext>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <CustomerSearch onUserClick={handleCustomerOpen} />
          </Grid>
        </Grid>
      </Container>

      <SingleOnboarding
        selectEntry={onbCustomer}
        handleClose={() => {
          setOnbDrawerOpen(false);
          setOnbCustomer(null);
        }}
        drawerOpen={onbDrawerOpen}
      />

      <Drawer
        anchor={"right"}
        open={managerSearchDrawerState}
        onClose={() => setManagerSearchDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <ManagerSearch onAccSelected={managerSearchDrawerReturn} />
      </Drawer>

      <Drawer
        anchor={"right"}
        open={aptSearchDrawerState}
        onClose={() => setAptSearchDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <ApartmentSearch onAptSelected={aptSearchDrawerReturn} />
      </Drawer>

      <CustomerDetailsPage
        customer={selectedCust}
        handleClose={() => setCustDrawerOpen(false)}
        drawerOpen={custDrawerOpen}
      />
    </Page>
  );
}

const TABLE_HEAD = [
  { id: "index", label: "ID", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "lasta", label: "Last Call", alignRight: false },
  { id: "app", label: "App", alignRight: false },
  { id: "sample", label: "Sample", alignRight: false },
  { id: "cart", label: "Cart", alignRight: false },
  { id: "source", label: "Source", alignRight: false },
  { id: "manager", label: "Manager", alignRight: false },
];

const OnboardingCard = ({
  selectEntry,
  onbStatus,
  onOpenCustomer,
  rowsPerPage,
  selectDir,
  selectCart,
  selectMgr,
  dnp,
  handleDirChange,
  handleChangeRowsPerPage,
  freeSampleCredit,
  freeSampleVerify,
  appDownloaded,
  subscriptionStatus,
  handleAppDownloadedChange,
  handleDnpChange,
  handleFreeSampleCreditChange,
  handleFreeSampleVerifyChange,
  handleSubscriptionStatusChange,
  selectApt,
  userActive,
  handleUserActiveChange,
}) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  const [checkedRows, setCheckedRows] = useState([]);

  const [search, setSearch] = useState("");

  const { getAccessToken, activeFranchise } = useAuth();

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const loadData = async () => {
    setLoadingData(true);
    setCheckedRows([]);

    let url = `/api/salesflags/mgract/onboard/query?skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&sort=_id&dir=${selectDir}`;

    if (onbStatus && onbStatus !== "all") {
      url += `&onb_status=${onbStatus}`;
    }

    if (selectCart === "all") {
    } else if (selectCart === "none") {
      url += "&cart=none";
    } else {
      url += `&cart=${selectCart}`;
    }

    url += `&manager=${selectMgr}`;

    if (freeSampleCredit !== "all") {
      url += `&free_sample_credited=${freeSampleCredit}`;
    }

    if (freeSampleVerify !== "all") {
      url += `&free_sample_status=${freeSampleVerify}`;
    }

    if (appDownloaded !== "all") {
      url += `&app_downloaded=${appDownloaded}`;
    }

    if (subscriptionStatus !== "all") {
      url += `&subscription=${subscriptionStatus}`;
    }

    if (dnp !== "all") {
      url += `&dnp=${dnp}`;
    }

    if (selectApt && selectApt !== "all") {
      url += `&apartment=${selectApt}`;
    }

    if (userActive && userActive !== "all") {
      url += `&user_active=${userActive}`;
    }

    if (search && search.length > 0) url += `&search=${search}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, [
    page,
    activeFranchise,
    selectDir,
    selectCart,
    rowsPerPage,
    selectMgr,
    search,
    subscriptionStatus,
    appDownloaded,
    freeSampleCredit,
    freeSampleVerify,
    dnp,
    selectApt,
    userActive,
  ]);

  const [bulkAssignModalState, setBulkAssignModalState] = useState(false);

  const totalLength = page * rowsPerPage + customerData.length;

  let lastDate = null;
  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
          mt={2}
          spacing={2}
        >
          <SearchStyle
            onChange={handleChangeQuery}
            placeholder="Search Phone number"
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            sx={{ mt: 4, mb: 4, color: "red" }}
            autoFocus
          />

          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              select
              id="free1-select"
              value={freeSampleCredit}
              onChange={(e) => {
                handleFreeSampleCreditChange(e);
                setPage(0);
              }}
              label="FS Credited"
              SelectProps={{
                style: { color: freeSampleCredit === "all" ? "black" : "red" },
              }}
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"true"}>Yes</MenuItem>
              <MenuItem value={"false"}>No</MenuItem>
            </TextField>

            <TextField
              select
              id="free2-select"
              value={freeSampleVerify}
              onChange={(e) => {
                handleFreeSampleVerifyChange(e);
                setPage(0);
              }}
              label="FS Status"
              SelectProps={{
                style: { color: freeSampleVerify === "all" ? "black" : "red" },
              }}
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"none"}>No Sample</MenuItem>
              <MenuItem value={"day1"}>Day1 Done</MenuItem>
              <MenuItem value={"day2"}>Day2 Done</MenuItem>
              <MenuItem value={"done"}>Day3 Done</MenuItem>
            </TextField>

            <TextField
              select
              id="subscribe-select"
              value={subscriptionStatus}
              onChange={(e) => {
                handleSubscriptionStatusChange(e);
                setPage(0);
              }}
              label="Subscription"
              SelectProps={{
                style: {
                  color: subscriptionStatus === "all" ? "black" : "red",
                },
              }}
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"true"}>Done</MenuItem>
              <MenuItem value={"false"}>Not Done</MenuItem>
            </TextField>

            <TextField
              select
              id="app-select"
              value={appDownloaded}
              onChange={(e) => {
                handleAppDownloadedChange(e);
                setPage(0);
              }}
              label="App Downloaded"
              SelectProps={{
                style: { color: appDownloaded === "all" ? "black" : "red" },
              }}
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"true"}>Yes</MenuItem>
              <MenuItem value={"false"}>No</MenuItem>
            </TextField>

            <TextField
              select
              id="app-select"
              value={dnp}
              onChange={(e) => {
                handleDnpChange(e);
                setPage(0);
              }}
              label="DNP"
              SelectProps={{
                style: { color: dnp === "all" ? "black" : "red" },
              }}
            >
              <MenuItem value={"0"}>0</MenuItem>
              <MenuItem value={"1"}>1</MenuItem>
              <MenuItem value={"2"}>2</MenuItem>
              <MenuItem value={"3"}>3</MenuItem>
              <MenuItem value={"4"}>4</MenuItem>
              <MenuItem value={"5"}>5</MenuItem>
              <MenuItem value={"all"}>All</MenuItem>
            </TextField>

            <TextField
              select
              id="user-act-select"
              value={userActive}
              onChange={(e) => {
                handleUserActiveChange(e);
                setPage(0);
              }}
              label="Active User"
              SelectProps={{
                style: { color: userActive === "all" ? "black" : "red" },
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
            </TextField>

            <TextField
              select
              id="dir-select"
              value={selectDir}
              onChange={(e) => handleDirChange(e)}
              label="Sort"
            >
              <MenuItem value={"a"}>Old First</MenuItem>
              <MenuItem value={"d"}>New First</MenuItem>
            </TextField>

            {(loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton
                onClick={() => {
                  if (page === 0) loadData();
                  else setPage(0);
                }}
              >
                <RefreshRounded />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <Divider sx={{ mt: 1 }} />

        <Table>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={
                        customerData?.length > 0 &&
                        checkedRows?.length === customerData?.length
                      }
                      onClick={(e) => {
                        if (e.target.checked === true) {
                          setCheckedRows(customerData.map((item) => item._id));
                        } else {
                          setCheckedRows([]);
                        }
                      }}
                    />
                  </TableCell>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      <b>{headCell.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.length > 0 &&
                  customerData.map((item, index) => {
                    let showDate = false;
                    let dt = getDateStringCustom(
                      parseInt(item?.user?._id.substring(0, 8), 16) * 1000,
                      "DD/MM/YYYY"
                    );

                    if (!lastDate) {
                      showDate = true;
                      lastDate = dt;
                    } else {
                      if (lastDate !== dt) {
                        showDate = true;
                      }
                    }
                    lastDate = dt;

                    return (
                      <>
                        {showDate && (
                          <TableRow>
                            <TableCell colSpan={10}><b>{dt}</b></TableCell>
                          </TableRow>
                        )}
                        <Tooltip title={item.comments} arrow placement="right">
                          <TableRow
                            hover
                            onClick={() => selectEntry(item)}
                            style={{
                              backgroundColor:
                                item.user && item.user.active === false
                                  ? "#ddd"
                                  : "white",
                            }}
                            key={index}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={checkedRows.includes(item._id)}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setCheckedRows([...checkedRows, item._id]);
                                  } else {
                                    setCheckedRows(
                                      checkedRows.filter(
                                        (id) => id !== item._id
                                      )
                                    );
                                  }
                                }}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </TableCell>
                            <TableCell>
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                            <TableCell
                              onClick={(e) => {
                                onOpenCustomer(item?.user);
                                e.stopPropagation();
                              }}
                            >
                              <b>
                                {(item.user &&
                                  item.user.name &&
                                  item.user.name) ||
                                  "--"}
                              </b>
                            </TableCell>
                            <TableCell>
                              <Label
                                variant="filled"
                                color={
                                  item?.onb_status === "completed"
                                    ? "success"
                                    : item?.onb_status === "later"
                                    ? "warning"
                                    : item?.onb_status === "not-interested"
                                    ? "info"
                                    : item?.onb_status === "duplicate"
                                    ? "secondary"
                                    : item?.onb_status === "ongoing"
                                    ? "primary"
                                    : item?.onb_status === "delivery"
                                    ? "black"
                                    : "error"
                                }
                              >
                                {item?.onb_status?.toUpperCase() || "--"}
                              </Label>
                            </TableCell>
                            <TableCell>
                              {(item.last_call > 0 &&
                                getDateStringCustom(
                                  item.last_call,
                                  "DD/MM/YY hh:mm A"
                                )) ||
                                "--"}
                            </TableCell>
                            <TableCell>
                              <Label
                                variant="outlined"
                                color={
                                  item.onb_details &&
                                  item.onb_details.app_download === true
                                    ? "success"
                                    : "error"
                                }
                              >
                                {item.onb_details &&
                                item.onb_details.app_download === true
                                  ? "Yes"
                                  : "No"}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <Label
                                variant="outlined"
                                color={
                                  item?.onb_details?.free_sample?.verified ===
                                  true
                                    ? "success"
                                    : item?.onb_details?.free_sample
                                        ?.credited === true
                                    ? "info"
                                    : "error"
                                }
                              >
                                {item?.onb_details?.free_sample?.verified ===
                                true
                                  ? "Completed"
                                  : item?.onb_details?.free_sample?.credited ===
                                    true
                                  ? "Credited"
                                  : "No"}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <Label
                                variant="outlined"
                                color={item.cart ? "info" : "error"}
                              >
                                {(item.cart && item.cart.name) || "No Cart"}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <Label
                                variant="outlined"
                                color={
                                  item.user_source == "app"
                                    ? "error"
                                    : item.user_source == "cart"
                                    ? "success"
                                    : "warning"
                                }
                              >
                                {(item.user_source &&
                                  item.user_source.toUpperCase()) ||
                                  "-"}
                              </Label>
                            </TableCell>
                            <TableCell>{item?.manager?.name || "-"}</TableCell>
                          </TableRow>
                        </Tooltip>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Typography variant="body">
                {checkedRows.length} Selected
              </Typography>
              {checkedRows.length > 0 && (
                <IconButton onClick={() => setBulkAssignModalState(true)}>
                  <AssignmentRounded />
                </IconButton>
              )}
            </Stack>
            <TablePagination
              component="div"
              count={totalLength < (page + 1) * rowsPerPage ? totalLength : -1}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onRowsPerPageChange={(e) => {
                handleChangeRowsPerPage(e.target.value);

                setPage(0);
              }}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              showFirstButton
              disabled={loadingData}
            />
          </Stack>
        </Table>
      </Container>

      <BulkAssignCRMModal
        open={bulkAssignModalState}
        handleClose={() => {
          setBulkAssignModalState(false);
        }}
        reloadData={loadData}
        data={checkedRows}
      />
    </Card>
  );
};

const BulkAssignCRMModal = ({ open, handleClose, data, reloadData }) => {
  const { getAccessToken, activeFranchise } = useAuth();
  const [loadingData, setLoadingData] = useState(false);

  const uploadData = async (acc) => {
    setLoadingData(true);

    const ret = await dataPost(
      "/api/salesflags/mgract/bulkassign",
      await getAccessToken(),
      { agent: acc._id, data: data },
      activeFranchise
    );

    if (ret.success === true) {
      reloadData();
      handleClose();
    } else {
      alert(ret.message || "Failed!");
    }
    setLoadingData(false);
  };

  const handleAccSelected = (acc) => {
    uploadData(acc);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Stack spacing={2} direction={"column"} p={2}>
        {(loadingData === true && <CircularProgress />) || (
          <>
            <Typography id="modal-modal-title" variant="h6">
              Assign Onboarding Data
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Assign {data?.length} {data?.length > 1 ? "entries" : "entry"} to:
            </Typography>
            <ManagerSearch onAccSelected={handleAccSelected} />
          </>
        )}
      </Stack>
    </Dialog>
  );
};
